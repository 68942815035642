// 最大存200个历史记录 {f:'fromPath',t:'toPath'}
let historyArr = [];

export function addHistory(fromPath,toPath) {
    if (isBack(fromPath, toPath)) {
        historyArr.pop();
    }else{
        historyArr.push({f:fromPath, t: toPath})
        if (historyArr.length > 200) {
            historyArr.shift();
        }
    }
}

export function isBack(fromPath,toPath) {
    console.log("isBack",historyArr,fromPath,toPath)
    let length = historyArr.length;
    if (length > 0) {
        const lastHistory = historyArr[length-1];
        return lastHistory.f === toPath && lastHistory.t === fromPath;
    }
    return false;
}
