import Vue from 'vue'
import VueRouter from 'vue-router'
import {addHistory} from "@/utils/back";
import {getAN, setCanBack} from "@/utils";
import store from "@/store";
import {uv} from "@/api/wxmp";
import cache from "@/utils/cache";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect : '/index'
  },
  {
    // 过渡页
    path: "/index",
    name:'index',
    component:()=>import('../views/Index')
  },
  {
    // 登录页
    path:'/logon',
    name:'logon',
    component:()=>import('../views/LogOnPage')
  },
  // {
  //   // 登录页 h5    先支付后注册：支付完成后跳转注册页带上订单信息。注册时绑定支付信息
  //   //             先注册后支付：注册完成后跳转支付页带上卡号信息。支付完绑定卡号信息
  //   path:'/logon2/:alipayKey',
  //   name:'logon2',
  //   component:()=>import('../views/LogOnPage')
  // },


  // {
  //   // 活动页
  //   path:'/active',
  //   name:'active',
  //   component:()=>import('../views/ActivePage')
  // },


  {
    // 活动页测试
    path:'/active',
    name:'activeNew',
    component:()=>import('../views/ActivePageNew'),
    // meta: {title: '套餐三选一 充100得280'}
  },
  {
    //设备激活页面
    path: '/equipmentActive',
    name:'equipmentActive',
    component:()=>import('../views/EquipmentActive'),
    meta: {title: '全国通用大流量 首充特惠'}
  },
  {
    // 活动页测试
    path:'/activeOld',
    name:'activeOld',
    component:()=>import('../views/ActivePageOld'),
    meta: {title: '活动4'}
  },
  {
    // 首页
    path: '/home',
    name:'home',
    component:()=>import('../views/Home'),
    meta:{title: '首页'},
  },
  {
    // 主页
    path:'/flowCard',
    name:'flowCard',
    component:()=>import('../views/FlowCard'),
    meta:{title:"大流量卡"},
  },
  {
    // 套餐列表页
    path: '/setMeal',
    name:'setMeal',
    component:()=>import('../views/setMeal/Index'),
    meta : {title: '套餐订购'}
  },
  {
    // 套餐列表页-自动续费选择套餐
    path: '/selectMeal',
    name:'selectMeal',
    component:()=>import('../views/setMeal/SelectIndex'),
    meta : {title: '套餐选择'}
  },
  {
    // 优惠卷页面
    path: '/coupon',
    name:'coupon',
    component:()=>import('../views/coupon/Index'),
    meta : {title: '优惠卷'}
  },
  {
    // 更多页面
    path: '/more',
    name:'more',
    component:()=>import('../views/more/Index'),
    meta:{title: '【流量充值】更多功能'}
  },
  {
    // 实名认证页面
    path: '/realName',
    name:'realName',
    component:()=>import('../views/realName/Index'),
    meta:{title: '实名'}
  },
  {
    // 主页
    path: '/recharge',
    name:'recharge',
    component:()=>import('../views/recharge/Index'),
    meta:{title: '余额充值'},
  },
  {
    // 智能检测页
    path:'/diagnosis',
    name:'diagnosis',
    component:()=>import('../views/diagnosis/Index'),
    meta:{title: '智能诊断'}
  },
  {
    path:'/alipaySuccess',
    name:'alipaySuccess',
    component:()=>import('../views/AlipaySuccess')
  },
  {
    path:'/alipaySuccess2',
    name:'alipaySuccess2',
    component:()=>import('../views/AlipaySuccess2')
  },
  {
    path:'/alipayGuide',
    name:'alipayGuide',
    component:()=>import('../views/AlipayGuide'),
  },
  {
    path:'/fei50alipayGuide',
    name:'fei50alipayGuide',
    component:()=>import('../views/Fei50AlipayGuide'),
  },
  {
    // 2024年6月30日09:36:32 was 检查到没有使用这个页面
    path:'/wxpayGuide',
    name:'wxpayGuide',
    component:()=>import('../views/WxpayGuide'),
  },
  {
    path:'/test',
    name:'test',
    component:()=>import('../views/Test'),
  },
  {
    path:'/weixinGuide',
    name:'weixinGuide',
    component:()=>import('../views/WeixinGuide'),
  },
  {
    // 问题页面
    path:"/problem",
    name:"problem",
    component : () => import("../views/problem/Index"),
    meta:{title: '常见问题'}
  },
  {
    // 设备问题页面
    path:"/sbProblem",
    name:"sbProblem",
    component : () => import("../views/SbProblem"),
    meta:{title: '常见问题'}
  },
  {
    // 主页
    path:"/businessChange",
    name:"businessChange",
    component : () => import("../views/businessChange/Index"),
    meta:{title: '网络优选'}
  },
  {
    // 套餐订购详情页面
    path:"/orderV2",
    name:"orderV2",
    component : () => import("../views/setMeal/orderV2/Index"),
    meta : {title: '套餐订购'}
  },
  {
    // 加油包订购页面
    path:"/orderV3",
    name:"orderV3",
    component : () => import("../views/setMeal/orderV2/Index2"),
    meta : {title: '套餐订购'}
  },
  {
    // 协议页面
    path:"/xieyi",
    name:"xieyi",
    component : () => import("../views/setMeal/orderV2/xieyi"),
    meta : {title: '平台协议'}
  },
  {
    // 选择优惠卷页面
    path:"/selectCoupon",
    name:"selectCoupon",
    component : () => import("../views/setMeal/orderV2/selectCoupon"),
    meta : {title: '优惠卷'}
  },
  {
    // 投诉页面
    path: "/complaint",
    name:"complaint",
    component : () => import("../views/complaint/Index"),
    meta : {title: '投诉'}
  },
  {
    // 投诉卖家页面
    path: '/complaintSeller',
    name:"complaintSeller",
    component : () => import("../views/complaint/complaintSeller"),
    meta:{title:'投诉'}
  },
  {
    // 套餐账单页面
    path: '/bill',
    name:"bill",
    component : () => import("../views/bill/Index"),
    meta : {title: '套餐账单'}
  },
  {
    // 套餐账单页面
    path: '/oldRecord',
    name:"oldRecord",
    component : () => import("../views/oldRecord/Index"),
    meta : {title: '历史账单'}
  },
  {
    // 余额账单页面
    path: '/detailed',
    name:"detailed",
    component : () => import("../views/detailed/Index"),
    meta : {title: '支付详情'}
  },
  {
    // 业务变更页面
    path: '/renew',
    name: 'renew',
    component : () => import('../views/renew/Index'),
    meta:{title: '业务变更'}
  },
  {
    // 防止失联页面
    path: '/contact',
    name: 'contact',
    component : () => import('../views/contact/Index'),
    meta : {title: '【流量卡】收藏链接'}
  },
  {
    // 活动页面 三合一
    path:'/huodong',
    name: 'huodong',
    component : () => import('../views/huodong/Index'),
    meta:{title:'活动'}
  },
  {
    //支付方式
    path:'/paymentMethod',
    name:'paymentMethod',
    component:()=>import('../views/PaymentMethod/Index'),
    // meta:{title:'充50得240活动'}
    meta:{title:'选择支付方式'}
  },
  {
    //支付方式
    path:'/choosePay',
    name:'choosePay',
    component:()=>import('../views/PaymentMethod/Index2'),
    // meta:{title:'充50得240活动'}
    meta:{title:'线上网厅|收银台'}
  },
  {
    //成功返回页
    path:'/successReturn',
    name:'successReturn',
    component:()=>import('../views/successReturn/Index'),
    meta:{title:'支付成功跳转微信'}
  },
  {
    //代理商门户
    path:'/appChnlDetail1',
    name:'appChnlDetail1',
    component:()=>import('../views/appChnlDetail/Index1'),
    meta:{title:'代理商门户'}
  },
  {
    //代理商门户
    path:'/appChnlDetail2',
    name:'appChnlDetail2',
    component:()=>import('../views/appChnlDetail/Index2'),
    meta:{title:'代理商门户'}
  },
  {
    //客服过渡页
    path:'/kefu',
    name:'kefu',
    component:()=>import('../views/Kefu'),
    meta:{title:'卡号诊断'}
  },
  {
    //检测界面--输入卡号
    path:'/logon2',
    name:'logon2',
    component:()=>import('../views/LogOnPage2'),
    meta:{title:'输入卡号'}
  },
  {

    //申请售后页面
    path: '/afterService',
    name:'afterService',
    component:()=>import('../views/afterService/index'),
    meta:{title: '申请售后'},
  },
    //申请退款页面
  {
    path: '/refund',
    name: 'refund',
    component: () => import('../views/refund/index'),
    meta: {title:'申请退款'},
  },
    //无法使用页面
  {
    path: '/unavailable',
    name: 'unavailable',
    component: () => import('../views/unavailable/index'),
    meta: {title:'无法使用'},
  },
  {
    //换卡选项
    path:'/huankaxuanxiang',
    name:'huankaxuanxiang',
    component:()=>import('../views/huanka/xuanxiang'),
    meta:{title:'申请换卡'}
  },
  {
    //换卡
    path:'/huanka',
    name:'huanka',
    component:()=>import('../views/huanka'),
    meta:{title:'换卡'}
  },
  {
    //商户充值
    path:'/cz',
    name:'chongzhi',
    component:()=>import('../views/ChongZhi'),
    meta:{title:'商户充值'}
  },
  {
    //刷单测试链接
    path:'/sdcslj',
    name:'sdcslj',
    component:()=>import('../views/Sdcslj'),
    meta:{title:'刷单测试链接'}
  },
  {
    //点金计划
    path:'/goldplan',
    name:'goldplan',
    component:()=>import('../views/GoldPlan'),
    meta:{title:'点金计划'}
  },
    //授权二维码
  {
    path: '/shouquan',
    name: 'shouquan',
    component: () => import('../views/ShouQuan'),
    meta:{title:'授权'}
  },
  //退款详情页面
  {
    path: '/refundDetailsPage',
    name: 'refundDetailsPage',
    component: () => import('../views/RefundDetailsPage'),
    meta:{title:'退款详情'}
  },
  //退款订单疑惑页面
  {
    path: '/refundIssue',
    name: 'refundIssue',
    component: () => import('../views/RefundIssue'),
    meta:{title:'解决退款订单问题'}
  },
  //投诉商家
  {
    path: '/complaintMerchant',
    name: 'complaintMerchant',
    component: () => import('../views/ComplaintMerchant'),
    meta:{title:'投诉商家'}
  },
    //在线客服
  {
    path:'/onlineService',
    name: 'onlineService',
    component: () => import('../views/OnlineService'),
    meta:{title:'在线咨询'}
  },
  // 跳转
  {
    path: '/jump',
    name: 'jump',
    component: () => import('../views/jump'),
    meta:{title:'跳转中'}
  },
    //开卡激活
  {
    path: '/qrcodeyd',
    name: 'qrcodeyd',
    component: () => import('../views/Qrcodeyd'),
    meta:{title:'开卡激活'}
  },
    //扫码页面
  {
    path: '/ScanCodePage',
    name: 'ScanCodePage',
    component: () => import('@/views/ScanCodePage.vue'),
    meta:{title:'扫码页面'},
  },
  //支付宝付款页面
  {
    path:'/PaymentPage',
    name:'PaymentPage',
    component : () => import('@/views/PaymentPage'),
    meta : {title : '支付宝付款'}
  },
  //微信支付过渡页
  {
    path:'/WxPayGuodu',
    name:'WxPayGuodu',
    component : () => import('@/views/WxPayGuodu'),
    meta : {title : '微信支付'}
  },
  //企业微信客服链接
  {
    path:'/qywxkf',
    name:'QywxKefu',
    component : () => import('@/views/QywxKefu'),
    meta : {title : ''}
  },
  //企业微信客服测试链接
  {
    path:'/kfcs',
    name:'QywxKefuCeshi',
    component : () => import('@/views/QywxKefuCeshi'),
    meta : {title : ''}
  },
    //虚拟宝贝发货确认
  {
    path:'/agiso',
    name:'agiso',
    component : () => import('@/views/Agiso'),
    meta : {title : ''}
  },
    //ios挽歌微信支付过渡页面
  {
    path:'/iosWGguodu',
    name:'iosWGguodu',
    component : () => import('@/views/IosWGguodu'),
    meta : {title : '微信APP扫码支付'}
  },
    //Android挽歌微信支付过渡页面
  {
    path:'/AndroidWGguodu',
    name:'AndroidWGguodu',
    component : () => import('@/views/AndroidWGguodu'),
    meta : {title : '长按识别二维码支付'}
  },
  {
    path:'/AndroidWGguodu2',
    name:'AndroidWGguodu2',
    component : () => import('@/views/AndroidWGguodu2'),
    meta : {title : '长按识别二维码支付'}
  },
  {
    path:'/pddtc',
    name:'pddtc',
    component : () => import('@/views/PaymentMethod/pddtc'),
    meta : {title : '拼多多充值'}
  },
    //设备电池采购页面
  {
    path:'/batteryBuy',
    name:'batteryBuy',
    component : () => import('@/views/BatteryBuy'),
    meta: {title: '电池采购'}
  }
]



const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  console.log("跳转路由",location)
  return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next)=>{
  addHistory(from.path, to.path);
  if(to.meta.title){
    document.title = to.meta.title;
  }
  console.log('router beforeEach',from.path,to.path)
  const toPath = to.path;
  const fromPath = from.path;
  let openId1 = store.getters.openId1;
  if (openId1) {
    if (toPath === '/active' || toPath === '/logon' || fromPath === '/active' || fromPath === '/logon') {
      const toPathNew = toPath.substring(1);
      // 统计uv
      console.log("统计uv",toPathNew,openId1)
      const type = cache.session.get('type');
      try {
        const {n} = getAN();
        uv({page: toPathNew, openId: openId1, type: type, cardNo:n}).then(res => {})
      } catch (e) {
      }
    }
  }
  next()
  setCanBack(2)
})

// let u = navigator.userAgent
// let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
// router.afterEach(async (to, from) => {
//   if (!isAndroid) { // IOS
//     if (window.entryUrl == '' || window.entryUrl == undefined) {
//       const url = location.href.split('#')[0]
//       window.entryUrl = url
//     } else {
//       console.log('IOS2', window.entryUrl)
//     }
//   } else { // 安卓
//     window.entryUrl = location.href.split('#')[0]
//   }
// })


export default router
