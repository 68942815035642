<!--
  命令式组件:子组件
  MessageBox.vue -->
<template>
  <div class="zljszfsbtc">
    <van-popup :value="value" @input="handleInput" :close-on-click-overlay="closeOnClickOverlay" :style="{width:'85%'}" >

<!--      <div class="shouyetanchuang">
        <div class="close-btn"><span @click="handleClose">关闭</span></div>
        <div class="content-container">
          <div>
            <div class="title">{{ title }}</div>
            <div class="content">
              <div v-if="content" v-html="content"></div>
              <slot></slot>
            </div>
            <div class="foot">
              <div class="btn" @click="onCancel">{{ cancelText }}</div>
              <div class="btn btn-main" @click="onConfirm">{{ confirmText }}</div>
            </div>
          </div>
        </div>
      </div>-->

      <div class="close-btn" @click="handleClose">关闭</div>
      <div class="shouyetanchuang" @click="handleAnyClose">
        <div class="bjImg">
          <img class="bg" src="../assets/img/popupbj.png" alt=""/>
          <img class="bg2" src="../assets/img/popupbj2.png" alt=""/>
          <div class="title_text">{{ title }}<slot name="header"/></div>
        </div>

        <div class="content">
          <div class="text">
            <div v-if="content" v-html="content"></div>
            <slot></slot>
          </div>

          <div class="foot">
            <button class="btn1" @click.stop="onCancel" v-show="cancelText">{{ cancelText }}</button>
            <button class="btn2" @click.stop="onConfirm" v-show="confirmText">{{ confirmText }}</button>
            <slot name="footer"/>
          </div>

          <div class="smallButton"><slot name="textBtn"/></div>
        </div>
      </div>





    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'Zljszfsbtc',
  props: {
    closeOnClickOverlay: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: Boolean,
      required: true,
      default: true,
    },
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      // required: true
    },
    cancelText: {
      type: String,
      required: true
    },
    confirmText: {
      type: String,
      required: true
    },
    onConfirm: {
      type: Function,
      default: () => {
      }
    },
    onCancel: {
      type: Function,
      default: () => {
      }
    },
    onClose: {
      type: Function,
      default: () => {
      }
    },
  },
  methods: {
    handleAnyClose() {
      if (this.closeOnClickOverlay) {
        this.handleClose();
      }
    },
    handleClose() {
      this.handleInput(false)
      this.onClose && this.onClose();
    },
    handleInput(value) {
      this.$emit('input', value);
    },
    setEvent(type) {
      this.onClick(type)
    },
  },
};
</script>
<style scoped lang="scss">
/*.shouyetanchuang{
  width: 90vw;box-sizing: border-box;
  .close-btn{
    color: #00e5ff;text-align: right;margin-bottom: 10px; font-size: 16px;
  }
  .content-container{
    background: #FFFFFF;border-radius: 10px;
  }
  .title{
    padding: 20px 0;font-size: 18px;font-weight: 600;text-align: center;
  }
  .content{
    margin: 0 20px 20px 20px;max-height:30vh;overflow-y: scroll;line-height: 1.8;font-size: 16px;
    div{
      &:first-child{
        margin-top: 0;
      }
      margin-top: 10px;
    }
  }

  .foot{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
    padding-bottom: 20px;
    font-size: 16px;
    .btn{
      width: 100px;

      height: 47px;
      color: #3476fe;
      text-align: center;
      background: rgba(52,118,254,.1);
      border: 0;
      border-radius: 94px;
      font-size: 16px;
      line-height: 47px;
      flex: none;
    }
    .btn-main{
      max-width: 130px;
      color: #fff;
      background: #3476fe;
    }
  }

}*/
.close-btn{
  color: #00e5ff;text-align: right;font-size: 16px;margin-bottom: -20px;
}
.bjImg{
  position: relative;
  margin-top: 40px;
  .bg{
    width: 100%;
    display: block;
  }
  .bg2{
    width: 80px;
    height: 80px;
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -50%;
  }
  .title_text{
    margin: 0;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    font-size: 19px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
}
.shouyetanchuang{
  background: #FFFFFF;
  border-radius: 10px;
  padding-bottom: 20px;
  .content{
    margin: 20px 20px 0;
    .text{
      margin-bottom: 10px;
      font-size: 14.4px;
      line-height: 24px;
      color: #2e2d2d;
      white-space: pre-wrap;
      span{
        color: #ff980b;
        font-weight: bold;
      }
    }
    .foot{
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 20px;
      button{
        height: 44px;
        text-align: center;
        border-radius: 50px;
        line-height: 44px;
        padding: 0 20px;
        font-size: 16px;
      }
      .btn1{
        color: #409eff;
        background: rgba(64, 158, 255, .12) !important;
      }
      .btn2{
        color: #fff;
        background: #409eff;
      }
    }
    .smallButton{
      margin-top: 14px;
      text-align: center;
      font-size: 14px;
      color: #adadad;
    }
  }
}
</style>
<style scoped>
.zljszfsbtc >>> .van-popup{
  background: none;
}
>>>.van-popup__close-icon--top-right{
  right: 0;
  top: 10px;
  color: #FFFFFF;
}
</style>
