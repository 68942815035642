import request from "@/utils/request";

/**
 * 获取openId
 * @param code 微信授权code
 * @returns {*}
 */
export function getOpenId(appId,code) {
    return request({
        url: 'wx/mp/getOpenId',
        method: 'get',
        params: {appId,code}
    })
}

/**
 * 获取支付信息
 * @param openId1 用户的openId
 * @param openId2 公众号收取按成功后的openId
 * @param appId2
 * @returns {*}
 */
export function getPayInfo(openId1,openId2,appId2) {
    return request({
        url: 'wx/mp/pay',
        method: 'get',
        params: {openId1,openId2,appId2}
    })
}

/**
 * 获取支付信息
 * @returns {*}
 */
export function getPayInfo2(data) {
    return request({
        url: 'wx/mp/pay2',
        method: 'post',
        data
    })
}

/**
 * 记录pv、uv
 * @param params 格式{index:激活页面样式下标, visitorId:用户标识}
 * @returns {*}
 */
export function activePageStatistics(params) {
    return request({
        url: 'wx/mp/activePageStatistics',
        method: 'get',
        params
    })
}

export function uv(params){
    return request({
        url: 'wx/mp/uv',
        method: 'get',
        params
    })
}


/**
 * 获取微信商户支付信息
 * @returns {*}
 */
export function shanghuchongzhi(data) {
    return request({
        url: 'wx/mp/shanghuchongzhi',
        method: 'post',
        data
    })
}

/**
 * 获取刷单测试链接配置
 * @returns {*}
 */
export function sdcsljpz(id,appId,openId) {
    return request({
        url: 'wx/mp/sdcsljpz',
        method: 'get',
        params: {id,appId,openId}
    })
}

/**
 * 获取汇付商户支付信息
 * @returns {*}
 */
export function huifushanghuchongzhi(data) {
    return request({
        url: 'wx/mp/huifushanghuchongzhi',
        method: 'post',
        data
    })
}

/**
 * 获取应当跳转的页面
 * @param openId
 * @returns {*}
 */
export function getJump(openId) {
    return request({
        url: 'wx/mp/checkJump',
        method: 'get',
        params: {openId}
    })
}


/**
 * 获取应当跳转的页面
 * @returns {*}
 */
export function getJump2(data) {
    return request({
        url: 'wx/mp/checkJump2',
        method: 'post',
        data
    })
}


/**
 * 获取当前正在使用的公众号
 * @returns {*}
 */
export function getCurrentMp() {
    return request({
        url: 'wx/mp/getCurrentMp',
        method: 'get'
    })
}

/**
 * 获取当前正在使用的支付宝支付域名
 * @returns {*}
 */
export function getAlipayDomain() {
    return request({
        url: 'wx/mp/getAlipayDomain',
        method: 'get'
    })
}

/**
 * 获取充值80页面配置
 * @returns {*}
 */
export function getPageConfig() {
    return request({
        url: 'wx/mp/pageConfig',
        method: 'get'
    })
}

/**
 * 获取充值80页面配置
 * @returns {*}
 */
export function register(data) {
    return request({
        url: 'wx/mp/register',
        method: 'post',
        data
    })
}

/**
 * 获取充值80页面配置
 * @returns {*}
 */
export function register2(data) {
    return request({
        url: 'wx/mp/register2',
        method: 'post',
        data
    })
}

/**
 * 赠送激活活动
 * @returns {*}
 */
export function zengsongjihuohuodong(cardNo) {
    return request({
        url: 'wx/mp/zengsongjihuohuodong',
        method: 'get',
        params: {cardNo}
    })
}

/**
 * 记录日志
 * @returns {*}
 */
export function recordLog(data) {
    // return request({
    //     url: 'wx/mp/log',
    //     method: 'post',
    //     data
    // })
}

/**
 * 获取支付宝支付信息
 * @param key
 * @returns {*}
 */
export function getAlipayInfo(key) {
    return request({
        url: 'wx/mp/alipayInfo',
        method: 'get',
        params: {key}
    })
}

/**
 * 获取支付宝支付信息2
 * @param key
 * @param a 流程 1=先充值后注册 2=先注册后充值
 * @param n 卡号
 * @param openId1 铂明公众号openId，用于判断是不是微信环境
 * @param environment 1=微信 2=H5
 * @returns {*}
 */
export function getAlipayInfo2(key,a,n,openId1,environment) {
    return request({
        url: 'wx/mp/alipayInfo2',
        method: 'get',
        params: {key,a,n,openId1,environment}
    })
}

export function getWangeWxInfo(key, cardNo) {
    return request({
        url: 'wx/mp/wangeWxInfo',
        method: 'get',
        params: {key, cardNo}
    })
}


/**
 * 支付宝商户充值
 * @returns {*}
 */
export function zhifubaoshanghuchongzhi(data){
    return request({
        url: 'wx/mp/zhifubaoshanghuchongzhi',
        method: 'post',
        data
    })
}

/**
 * 聚合易支付充值
 * @returns {*}
 */
export function juheyizhifuchongzhi(data){
    return request({
        url: 'wx/mp/juheyizhifuchongzhi',
        method: 'post',
        data
    })
}

/**
 * 挽歌支付充值
 * @returns {*}
 */
export function wangezhifuchongzhi(data){
    return request({
        url: 'wx/mp/wangezhifuchongzhi',
        method: 'post',
        data
    })
}

/**
 * 判断能不能微信支付
 */
export function getWeixinPay(data) {
    return request({
        url: 'wx/mp/weixinPay',
        method: 'post',
        data
    })
}

/**
 * 获取微信支付信息
 */
export function getWeixinPayInfo(data) {
    return request({
        url: 'wx/mp/weixinPayInfo',
        method: 'post',
        data
    })
}

/**
 * 判断支付宝支付是否成功
 * @param key
 * @returns {*}
 */
export function checkAlipaySuccess(key) {
    return request({
        url: 'wx/mp/checkAlipaySuccess',
        method: 'get',
        params: {key}
    })
}

/**
 * 判断支付宝支付是否成功
 * @param key
 * @param appId
 * @param outTradeNo
 * @param tradeNo
 * @returns {*}
 */
export function checkAlipaySuccess2(key,appId,outTradeNo,tradeNo) {
    return request({
        url: 'wx/mp/checkAlipaySuccess2',
        method: 'get',
        params: {key,appId,outTradeNo,tradeNo}
    })
}


/**
 * 获取公众号授权链接
 * @param appId 公众号id
 * @param url 授权成功跳转链接
 * @returns {*}
 */
export function getAuthUrl(appId,url) {
    return request({
        url: 'wx/mp/getAuthUrl',
        method: 'post',
        data: {appId,url}
    })
}



/**
 * 记录日志
 * @returns {*}
 */
export function commonLog(data) {
    return request({
        url: 'wx/mp/commonLog',
        method: 'post',
        data
    })
}

/**
 *
 * @returns {*}
 */
export function jinmaiBuyOrder(cardNo,packageId,cardTicketIds,selectIndex,needPayMoney,dgsl,jsb) {
    return request({
        url: 'wx/mp/jinmaiBuyOrder',
        method: 'get',
        params: {cardNo,packageId,cardTicketIds,selectIndex,needPayMoney,dgsl,jsb}
    })
}

/**
 *
 * @returns {*}
 */
export function enterPage(kahao) {
    return Promise.resolve();
    // return request({
    //     url: 'wx/mp/enterPage',
    //     method: 'get',
    //     params: {kahao}
    // })

    // 2024年9月2日16:22 was 技术部-内部
    // 这个逻辑要不要
    // 客户进入logon或者active页面，5分钟没有激活的外呼
    // yd 技术部-内部 可以去掉
}

/**
 *
 * @returns {*}
 */
export function getJumpLink(params) {
    return request({
        url: 'wx/mp/getJumpLink',
        method: 'get',
        params
    })
}


export function saveCacheWxPayInfo(data) {
    return request({
        url: 'wx/mp/cacheWxPayInfo',
        method: 'post',
        data
    })
}

export function getCacheWxPayInfo(params) {
    return request({
        url: 'wx/mp/cacheWxPayInfo',
        method: 'get',
        params
    })
}

export function getCardType(cardNo,type) {
    return request({
        url: 'iot/getCardType',
        method: 'get',
        params: {cardNo, type}
    })
}
