// 命令式组件:js文件 showMsg.js
// 引入子组件
import Zljszfsbtc from "./Zljszfsbtc.vue";
// 引入Vue
import Vue from 'vue'

export function showZljszfsbtc(title,content,confirmText,cancelText, confirmCb, cancelCb, closeCb) {
    if (Vue.zljszfsbtc) {
        return Vue.zljszfsbtc.unMountMethod;
    }


    // 创建一个新的div元素
    const div = document.createElement('div');
    // 将div添加到body中
    document.body.appendChild(div);

    // 使用Vue.extend创建一个组件构造器，相当于拷贝了一份组件
    const ZljszfsbtcConstructor = Vue.extend(Zljszfsbtc);
    // 创建组件实例，进行传参
    const instance = new ZljszfsbtcConstructor({
        // 传递数据
        propsData: {
            title,content,confirmText,cancelText,
            onConfirm: ()=>{
                unMount();
                confirmCb && confirmCb();
            },
            onCancel: ()=>{
                unMount();
                cancelCb && cancelCb();
            },
            onClose: ()=>{
                unMount();
            }
        },
    });

    function unMount() {
        closeCb && closeCb();
        instance.$el.remove(); // 关闭时从DOM中移除div
        instance.$destroy(); // 销毁Vue实例
        Vue.zljszfsbtc = null;
    }

    // 挂载组件实例到div上
    instance.unMountMethod = unMount;
    instance.$mount(div);
    Vue.zljszfsbtc = instance;
    return () => {
        // 如果你的组件是一个对话框或类似需要手动关闭的，你可能还需要处理它的生命周期或提供一个关闭方法
        // 例如，你可以在这里监听组件的某些事件来决定何时卸载它
        unMount();
    }

}
