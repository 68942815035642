import request from "@/utils/request";

export function checkInfo(params) {
    return request({
        url: `wx/cp/2/checkInfo`,
        method: 'get',
        params
    });
}

export function getCskflj() {
    return request({
        url: `wx/cp/2/getCskflj`,
        method: 'get',
    });
}
