import Vue from 'vue'
import Vuex from 'vuex'
import cache from "@/utils/cache";
import {commonLog} from "@/api/wxmp";
import { Toast } from 'vant';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    prePay: {}, // 统一支付 {zhifubanwei: 'wushibanwei/feiwushibanwei', canshu: 支付请求参数, res: {支付响应对象}}
    weixinyuming: true, // 2024年5月29日17:02:07 was 微信新增切换域名功能，保留原来的逻辑，这个用来一键切换 true新逻辑 false旧逻辑
    appId1: cache.local.get('appId1') || '', // 公众号id（主）
    openId1: cache.local.get('openId1') || '', // 用户openId（主）
    appId2: cache.local.get('appId2') || '', // 公众号id（支付）
    openId2: cache.local.get('openId2') || '', // 用户openId（支付）
    appId3: cache.local.get('appId3') || '', // 精品短剧公众号id
    openId3: cache.local.get('openId3') || '', // 精品短剧openId
    jumpRequest: {},
    jumpResponse: {},
    logArr: ['日志'],
    cardNo: cache.local.get('cardNo') || '', // 当前选中的卡号
    showCardNo: cache.local.get('showCardNo') || '', // 当前选中的卡号的显示卡号
  },
  getters: {
    appId1(state){
      return state.appId1 || cache.local.get('appId1');
    },
    appId2(state){
      return state.appId2 || cache.local.get('appId2');
    },
    appId3(state){
      return state.appId3 || cache.local.get('appId3');
    },
    openId1(state){
      return state.openId1 || cache.local.get('openId1');
    },
    openId2(state){
      return state.openId2 || cache.local.get('openId2');
    },
    openId3(state){
      return state.openId3 || cache.local.get('openId3');
    },
    visitorId(state) {
      return state.openId1 || cache.local.get('openId1') || cache.local.get('fp');
    },
    commonInfo(state,getters){
      return {
        appId1: getters.appId1,
        appId2: getters.appId2,
        appId3: getters.appId3,
        openId1: getters.openId1,
        openId2: getters.openId2,
        openId3: getters.openId3,
        a: cache.session.get('a'),
        n: cache.session.get('n')
      }
    }
  },
  mutations: {
    addLog(state, log){
      state.logArr.push(log)
      let a = cache.session.get('a');
      if (a === '3') {
        cache.local.set('testLog',state.logArr.join('|'))
      }
    },
    setAppId1(state, appId) {
      state.appId1 = appId;
      cache.local.set('appId1', appId);
    },
    setOpenId1(state, openId) {
      state.openId1 = openId;
      cache.local.set('openId1', openId);
    },
    setAppId2(state, appId) {
      state.appId2 = appId;
      cache.local.set('appId2', appId);
    },
    setOpenId2(state, openId) {
      state.openId2 = openId;
      cache.local.set('openId2', openId);
    },
    setAppId3(state, appId) {
      state.appId3 = appId;
      cache.local.set('appId3', appId);
    },
    setOpenId3(state, openId) {
      state.openId3 = openId;
      cache.local.set('openId3', openId);
    },
    setCardNo(state, cardNo) {
      // 切换的卡号与当前的卡号不同的时候才切换
      if (state.cardNo !== cardNo) {
        state.cardNo = cardNo;
        cache.local.set('cardNo', cardNo);
        // console.log("setCardNoxxxxx.....1",cardNo)
        // console.trace();
      }
    },
    setShowCardNo(state, cardNo) {
      cache.local.set('showCardNo', cardNo);
      state.showCardNo = cardNo;
    }
  },
  actions: {
    recordLog({commit,getters},log){
      const a = cache.session.get('a');
      // Vue.$toast(a)
      if (a === '3') {
        Toast(a+log)
        const openId1 = getters.openId1;
        commonLog('跳3first'+openId1+' '+log).then(res=>{
          commit('addLog', log);
        })
      }
    }
  },
  modules: {
  }
})
