import {formatMoney} from "@/utils/index";

const filters = {
    // {{ money|moneyFilter }}
    // {{ money|moneyFilter(3) }}
    moneyFilter(money,digits=2){
        return formatMoney(money,digits)
    },
    numberFilter(money, type = 0) {
        if (!money) {
            return 0;
        }
        return (money+'').split('.')[type]
    },
    cardNoFilter(cardNo) {
        if (cardNo && cardNo.length>14) {
            return '16988888888';
        }
        return cardNo;
    },
    cardNoFilter2(cardNo) {
        return (cardNo||'').replace(/(?=(\d{4})+$)/g, '-');
    },
}

export default{
    install(Vue){
        for (const filterName in filters) {
            Vue.filter(filterName, filters[filterName])
        }
    }
}
