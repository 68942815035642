import request from "@/utils/request2";
import axios from "axios";

/**
 * 通过卡号登录
 * @param ownNum 卡号
 * @returns {*}
 */
export function loginByCard(ownNum) {
    return request({
        url: 'user/base/loginByCard',
        method: 'get',
        params: {ownNum: ownNum}
    })
}

export function jinmaiCardInfo(iccid,customId) {
    return request({
        url: 'user/phoneCard/cardInfo',
        method: 'get',
        params: {iccid, customId}
    })
}

// 获取卡信息
export function cardInfo(iccid,customId) {
    return request({
        url: 'user/phoneCard/cardInfo',
        method: 'get',
        params: {iccid,customId}
    })
}

export function getTickets(ownNum,customId) {
    return request({
        url: 'user/phoneCard/getTickets',
        method: 'get',
        params: {ownNum,customId,applicableScene:1,status:1}
    })
}

// 添加新卡
export function bindingCard(ownNumber,customId) {
    return request({
        url: 'user/phoneCard/bandingCard',
        method: 'post',
        data: {ownNumber,customId}
    })
}

// 获取移动实名链接
export function getAuthUrl(iccid,phone) {
    return request({
        url: 'user/phoneCard/getAuthUrl',
        method: 'post',
        data: {iccid,phone}
    })
}

// 套餐订购页面-获取套餐列表
export function getPackageList(iccid,customId) {
    return request({
        url: 'user/phoneCard/getPackageList',
        method: 'get',
        params: {iccid,customId}
    })
}

/**
 * 开关自动续费基础包
 * @param ownNum   卡号
 * @param customId 用户id
 * @param autoPack 套餐id
 * @param isAuto   1开 2关
 * @returns {*}
 */
export function changeAuto(ownNum,customId,autoPack,isAuto) {
    return request({
        url: 'user/phoneCard/changeAuto',
        method: 'post',
        data: {ownNum,customId,autoPack,isAuto}
    })
}

/**
 * 开关自动续费加油包
 * @param ownNum 卡号
 * @param customId 用户id
 * @param autoPack 加油包id
 * @param isAuto 1开 2关
 * @returns {*}
 */
export function changeAutoReFuel(ownNum,customId,autoPack,isAuto) {
    return request({
        url: 'user/phoneCard/changeAutoReFuel',
        method: 'post',
        data: {ownNum,customId,autoPack,isAuto}
    })
}

/**
 *
 * @param iccid
 * @param customId 用户id
 * @param packageId 套餐id
 * @param postpone 延迟
 * @param payPlatform 支付平台 cardBalance(卡余额)
 * @param cardTicketIds 使用的优惠券id
 * @returns {*}
 */
export function buyOrder(iccid,customId,packageId,postpone=2,payPlatform='cardBalance',cardTicketIds) {
    return request({
        url: 'order/buyOrder',
        method: 'post',
        data: {iccid,customId,packageId,postpone,payPlatform,cardTicketIds}
    })
}

/**
 * 上传图片
 * @param file 文件对象
 * @returns {*}
 */
export function uploadImg(file) {
    let formData = new FormData();
    formData.append('files',file);
    return new Promise((resolve, reject)=>{
        axios.post('http://yw.ihaoma.ltd:29000/user/base/uploadImg',formData).then(res=>resolve(res.data))
    })
}

// http://yw.ihaoma.ltd:29000/user/base/saveCustomInfo?phone=123456&userRemark=aaa&ownNum=16904971577&submitType=2&img=custom_5z5pu2x0ruba,custom_myc7bxty2ad5,custom_i1shf0bzzmia&timestamp=1690875748000&customId=1404282&source=wxPublic&registerResource=2&resourceName=小朵智能
/**
 * 申请售后-退款、无法使用
 * @param phone 手机号
 * @param userRemark 无法使用-详情描述
 * @param ownNum 卡号
 * @param submitType 售后服务类型 1=申请退款 2=无法使用
 * @param img 图片
 * @param customId 用户id
 * @returns {*}
 */
export function saveCustomInfo(params) {
    return request({
        url: 'user/base/saveCustomInfo',
        method: 'get',
        params
    })
}

// http://yw.ihaoma.ltd:29000/user/base/checkRefundMoney?ownNum=16904971577&submitType=2&timestamp=1690876215000&customId=1404282&source=wxPublic&registerResource=2&resourceName=小朵智能
/**
 *
 * 获取售后服务列表
 * 上面的saveCustomInfo接口提交完成后，应该跳转新页面，通过这个接口获取用户已提交的售后服务列表，展示到界面上
 * @param ownNum 卡号
 * @param customId 用户id
 * @param submitType 售后服务类型 1=申请退款 2=无法使用 不传查所有
 * @returns {*}
 */
export function checkRefundMoney(ownNum,customId,submitType) {
    return request({
        url: 'user/base/checkRefundMoney',
        method: 'get',
        params: {ownNum,customId,submitType}
    })
}

// http://yw.ihaoma.ltd:29000/order/getOrderList?iccid=89860450092220049715&pageSize=10&page=1&status=1&timestamp=1691484880000&customId=1404282&source=wxPublic&registerResource=2&resourceName=%E5%B0%8F%E6%9C%B5%E6%99%BA%E8%83%BD
/**
 * 套餐订单
 * iccid,pageSize,page,status=1,customId,submitType=1
 * @param params
 * @returns {*}
 */
export function getOrderList(params) {
    return request({
        url: 'order/getOrderList',
        method: 'get',
        params
    })
}

// http://yw.ihaoma.ltd:29000/user/phoneCard/getCardBalanceRecord?page=1&pageSize=10&iccid=89860450092220049715&timestamp=1691486700000&customId=1404282&source=wxPublic&registerResource=2&resourceName=%E5%B0%8F%E6%9C%B5%E6%99%BA%E8%83%BD
/**
 * 余额订单
 * page pageSize iccid customId
 * @param params
 * @returns {*}
 */
export function getCardBalanceRecord(params) {
    return request({
        url: 'user/phoneCard/getCardBalanceRecord',
        method: 'get',
        params
    })
}

// http://yw.ihaoma.ltd:29000/user/phoneCard/untieCard
/**
 * 解绑
 * @param iccid iccid
 * @param customId 用户id
 * @returns {*}
 */
export function untieCard(iccid,customId) {
    return request({
        url: 'user/phoneCard/untieCard',
        method: 'post',
        data:{iccid, customId}
    })
}
