import cache from "@/utils/cache";
import router from "@/router";
import store from "@/store";
import {checkAlipaySuccess, getAlipayDomain, getJump2, saveCacheWxPayInfo} from "@/api/wxmp";
import {prePay, zfbsb} from "@/api/iot";
import {Toast} from "vant";
import {showZljszfsbtc} from "@/components/ZljszfsbtcJs";
import {checkInfo} from "@/api/wxcp";

export function getUrlParamsObj() {
    const search = location.search;
    if (!search) {
        return {}
    }
    let resultMap = {};
    let keyValueArr = search.replace('?', '').split('&');
    for (let i = 0; i < keyValueArr.length; i++) {
        const item = keyValueArr[i];
        const arr = item.split('=');
        if (arr.length === 2) {
            const arrValue1 = arr[1];
            if (arrValue1 !== '' && arrValue1 !== undefined && arrValue1 != null) {
                resultMap[arr[0]] = arrValue1;
            }
        }
    }
    return resultMap;
}

export function tansParams(params) {
    let arr = [];
    for (const propName of Object.keys(params)) {
        const value = params[propName];
        if (value !== null && value !== undefined && value !== 'null' && value !== '') {
            arr.push(propName + '=' + value);
        }
    }
    return arr.join('&')
}


export function getAN() {
    const a = cache.session.get('a');
    const n = cache.session.get('n');
    let result = {};
    if (a) {
        result.a = a;
    }
    if (n) {
        result.n = n;
    }
    return result;
}


export function isWeixin() {
    var inWx = (/MicroMessenger/i).test(navigator.userAgent);
    var inMp = (/MPAPP/i).test(navigator.userAgent);
    return inWx || inMp;
}

//验证手机号
export function validatePhone(val) {
    let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    return val && reg.test(val);
}

export function validateIdCard(val) {
    try {
        let w = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
        if (val && val.length === 18) {
            //身份证号码长度必须为18，只要校验位正确就算合法
            var crc = val.substring(17);
            var a = [];
            var sum = 0;
            for (var i = 0; i < 17; i++) {
                a.push(val.substring(i, i + 1));
                sum += parseInt(a[i], 10) * parseInt(w[i], 10);
            }
            sum %= 11;
            var res = "-1";
            switch (sum) {
                case 0: {
                    res = "1";
                    break;
                }
                case 1: {
                    res = "0";
                    break;
                }
                case 2: {
                    res = "X";
                    break;
                }
                case 3: {
                    res = "9";
                    break;
                }
                case 4: {
                    res = "8";
                    break;
                }
                case 5: {
                    res = "7";
                    break;
                }
                case 6: {
                    res = "6";
                    break;
                }
                case 7: {
                    res = "5";
                    break;
                }
                case 8: {
                    res = "4";
                    break;
                }
                case 9: {
                    res = "3";
                    break;
                }
                case 10: {
                    res = "2";
                    break;
                }
            }
            if (crc.toUpperCase() === res.toUpperCase()) {
                const idCardYear = val.substring(6, 10)
                const nowYear = new Date().getFullYear();
                let number = nowYear - parseInt(idCardYear);
                return number > 0 && number < 100;
            }
        }
    } catch (e) {
        console.log('验证身份证出错', e)
        return false;
    }
    return false;
}


/**
 * 加法运算，避免数据相加小数点后产生多位数和计算精度损失。
 *
 * @param num1加数1 | num2加数2
 */
export function numAdd(num1, num2) {
    var baseNum, baseNum1, baseNum2;
    try {
        baseNum1 = num1.toString().split(".")[1].length;
    } catch (e) {
        baseNum1 = 0;
    }
    try {
        baseNum2 = num2.toString().split(".")[1].length;
    } catch (e) {
        baseNum2 = 0;
    }
    baseNum = Math.pow(10, Math.max(baseNum1, baseNum2));
    return (num1 * baseNum + num2 * baseNum) / baseNum;
}

/**
 * 加法运算，避免数据相减小数点后产生多位数和计算精度损失。
 *
 * @param num1被减数 | num2减数
 */
export function numSub(num1, num2) {
    var baseNum, baseNum1, baseNum2;
    var precision;// 精度
    try {
        baseNum1 = num1.toString().split(".")[1].length;
    } catch (e) {
        baseNum1 = 0;
    }
    try {
        baseNum2 = num2.toString().split(".")[1].length;
    } catch (e) {
        baseNum2 = 0;
    }
    baseNum = Math.pow(10, Math.max(baseNum1, baseNum2));
    precision = (baseNum1 >= baseNum2) ? baseNum1 : baseNum2;
    return ((num1 * baseNum - num2 * baseNum) / baseNum).toFixed(precision);
}

/**
 * 乘法运算，避免数据相乘小数点后产生多位数和计算精度损失。
 *
 * @param num1被乘数 | num2乘数
 */
export function numMulti(num1, num2) {
    var baseNum = 0;
    try {
        baseNum += num1.toString().split(".")[1].length;
    } catch (e) {
    }
    try {
        baseNum += num2.toString().split(".")[1].length;
    } catch (e) {
    }
    return Number(num1.toString().replace(".", "")) * Number(num2.toString().replace(".", "")) / Math.pow(10, baseNum);
}

/**
 * 除法运算，避免数据相除小数点后产生多位数和计算精度损失。
 *
 * @param num1被除数 | num2除数
 */
export function numDiv(num1, num2) {
    var baseNum1 = 0, baseNum2 = 0;
    var baseNum3, baseNum4;
    try {
        baseNum1 = num1.toString().split(".")[1].length;
    } catch (e) {
        baseNum1 = 0;
    }
    try {
        baseNum2 = num2.toString().split(".")[1].length;
    } catch (e) {
        baseNum2 = 0;
    }
    baseNum3 = Number(num1.toString().replace(".", ""));
    baseNum4 = Number(num2.toString().replace(".", ""));
    return (baseNum3 / baseNum4) * Math.pow(10, baseNum2 - baseNum1);
}


export function saveCardInfo(info) {
    cache.local.setJSON('cardInfo', info);
}

export function getCardInfo() {
    return cache.local.getJSON('cardInfo') || {}
}

/**
 * 获取月份最后一天
 * @param date 日期对象。默认当前时间
 * @param monthOffset 月份偏移。默认为0，获取date月份最后一天
 * @returns {number} 最后一天
 */
export function endOfMonth(date = new Date(), monthOffset = 0) {
    date = new Date(handleIosTime(date));
    const year = date.getFullYear();
    const month = date.getMonth() + 1 + monthOffset; // 月份从0开始计算，需要加1
    const lastDay = new Date(year, month, 0).getDate();
    console.log(lastDay); //输出指定日期所在月份最后一天的日期
    return lastDay;
}

/**
 * 获取月份第一天的日期，格式yyyy-MM-dd 00:00:00
 * @param date
 * @param monthOffset
 * @returns {string}
 */
export function firstDateOfMonth(date = new Date(), monthOffset = 0) {
    date = new Date(handleIosTime(date));
    const year = date.getFullYear();
    const month = date.getMonth() + monthOffset; // 月份从0开始计算，需要加1
    return parseTime(new Date(year, month, 1), '{y}-{m}-{d} 00:00:00')
}

/**
 * 获取月份最后一天的日期，格式yyyy-MM-dd 00:00:00
 * @param date
 * @param monthOffset
 * @returns {string}
 */
export function lastDateOfMonth(date = new Date(), monthOffset = 0) {
    date = new Date(handleIosTime(date));
    const year = date.getFullYear();
    const month = date.getMonth() + monthOffset;
    date = new Date(year, month, endOfMonth(date, monthOffset));
    return parseTime(date, '{y}-{m}-{d} 00:00:00');
}

/**
 * 时间加减
 * @param date 时间
 * @param yearOffset 年偏移
 * @param monthOffset 月偏移
 * @param dayOffset 日偏移
 * @returns {string|null}
 */
export function offsetDate(date, yearOffset = 0, monthOffset = 0, dayOffset = 0) {
    date = new Date(handleIosTime(date));
    const year = date.getFullYear() + yearOffset;
    const month = date.getMonth() + monthOffset; // 月份从0开始计算，需要加1
    const day = date.getDate() + dayOffset;
    return parseTime(new Date(year, month, day), '{y}-{m}-{d} {h}:{i}:{s}');
}


/**
 * 获取两个日期相差的天数
 * @param date_1 日期1
 * @param date_2 日期2
 * @param type 1=向下取整  2=向上取整
 * @returns {number} 相差的天数
 */
export function getDiffDay(date_1, date_2,type=1) {
    // 计算两个日期之间的差值
    let totalDays, diffDate
    let myDate_1 = new Date(handleIosTime(date_1))
    let myDate_2 = new Date(handleIosTime(date_2))
    // 将两个日期都转换为毫秒格式，然后做差
    diffDate = Math.abs(myDate_1 - myDate_2) // 取相差毫秒数的绝对值

    totalDays = type===1 ? Math.floor(diffDate / (1000 * 3600 * 24)) : Math.ceil(diffDate / (1000 * 3600 * 24));
    // console.log(totalDays)
    // 相差的天数
    return totalDays
}

/**
 * 获取两个日期相差的小时
 * @param date_1 日期1
 * @param date_2 日期2
 * @param type 1=向下取整  2=向上取整
 * @returns {number} 相差的天数
 */
export function getDiffHour(date_1, date_2,type=1) {
    // 计算两个日期之间的差值
    let totalDays, diffDate
    let myDate_1 = new Date(handleIosTime(date_1))
    let myDate_2 = new Date(handleIosTime(date_2))
    // 将两个日期都转换为毫秒格式，然后做差
    diffDate = Math.abs(myDate_1 - myDate_2) // 取相差毫秒数的绝对值

    totalDays = type===1 ? Math.floor(diffDate / (1000 * 3600)) : Math.ceil(diffDate / (1000 * 3600));
    // console.log(totalDays)
    // 相差的小时
    return totalDays
}

/**
 * 比较两个日期
 * @param date1 时间1
 * @param date2 时间2
 * @returns {number} 如果第一个大于第二个返回正数,相等返回0,小于返回负数
 */
export function compareTime(date1, date2) {
    date1 = date1 || new Date();
    date2 = date2 || new Date();
    return new Date(handleIosTime(date1)).getTime() - new Date(handleIosTime(date2)).getTime();
}

export function handleIosTime(dateStr) {
    if (typeof dateStr === 'string') {
        return dateStr.replace(/-/g, "/");
    }
    return dateStr;
}

/**
 * 日期格式化
 * @param time 时间
 * @param pattern 格式化字符串
 * @returns {string|null}
 */
export function parseTime(time, pattern) {
    if (arguments.length === 0 || !time) {
        return null
    }
    const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
        date = time
    } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
            time = parseInt(time)
        } else if (typeof time === 'string') {
            time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '');
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
            time = time * 1000
        }
        date = new Date(time)
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    }
    return format.replace(/{([ymdhisa])+}/g, (result, key) => {
        let value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') {
            return ['日', '一', '二', '三', '四', '五', '六'][value]
        }
        if (result.length > 0 && value < 10) {
            value = '0' + value
        }
        return value || 0
    })
}

/**
 * 格式化金额
 * @param money 金额
 * @param digits 小数位数
 * @returns {string} 格式化后的金额字符串
 */
export function formatMoney(money, digits = 2) {
    let num = parseFloat(money);
    // 这里参考jQuery中判断是否数字$.isNumeric的源码 !isNaN(parseFloat(value)) && isFinite(value);
    return (!isNaN(num) && isFinite(money) ? num : 0).toFixed(digits);
}

/**
 * 设置是否可以返回
 * @param canBack 1=可以返回 2=不可以返回
 */
export function setCanBack(canBack = 1) {
    cache.local.set('canBack', canBack + '');
}

export function canBack() {
    return (cache.local.get('canBack') || '2') === '1'
}

export function setFirstLog(log) {
    cache.local.set('firstLog', log);
}

export function getFirstLog() {
    return cache.local.get('firstLog');
}


function isIos14AndMore() {
    try {
        var str = navigator.userAgent.toLowerCase();
        var ver = str.match(/cpu iphone os (.*?) like mac os/);
        if (!ver) {
            return false
        } else {
            return Number(ver[1].split('_')[0]) >= 14
        }
    } catch (e) {
        return false;
    }
}

const pathUrl = location.href.split('#')[0]

export function getUrl() {
    return isIos14AndMore() ? pathUrl : location.href.split('#')[0];
}

/**
 * 跳转我们的首页
 * @returns {boolean} true跳转我们的首页 false跳转明信通首页
 */
export function jumpOwn() {
    return true;
}

export function getWxkflj() {
    let wxkflj = cache.local.getJSON('wxkflj') || {
        wxkfljA: 'https://work.weixin.qq.com/kfid/kfcf122a2ac94c24386',
        wxkfljB: 'https://work.weixin.qq.com/kfid/kfcb4373b4188a8f21b'
    };
    let cardInfo = getCardInfo();
    const jihuotianshu = cardInfo.jihuotianshu || 99999;
    //2023年11月14日17:28:46 https://alidocs.dingtalk.com/i/nodes/7NkDwLng8Z9rqBwrhoxqjxOoJKMEvZBY
    // 1、在联系客服的时候，判断用户激活时间，如果激活时间是7天内到A链接；如果激活时间为7天外或者没有激活到B链接
    //	激活费时间判断从我们的统计表判断即可。
    //	A链接：https://work.weixin.qq.com/kfid/kfcf122a2ac94c24386
    //	B链接：https://work.weixin.qq.com/kfid/kfcb4373b4188a8f21b
    //	后台能够自定义A、B链接
    if (jihuotianshu <= 7) {
        return wxkflj.wxkfljA;
    } else {
        return wxkflj.wxkfljB;
    }
}

// 2024年1月14日16:54 yd使用was电脑发出 1、自定义=kefu页面是否要指向卡号诊断、如果关闭该功能则直接指向企业微信链接
export function jumpKefu(otherInfo={}) {
    let config = cache.local.getJSON('wxkflj') || {
        kefuyemianzhixiang: 1 // kefu页面指向 1=卡号诊断 2=企业微信
    };
    // 2024年10月27日13:44 yd 技术部-内部 APP需要修正的几点：安卓APP联系客服的时候，直接跳转到微信，不要停留在空白页面上。
    if (cache.local.get('app')) {
        const cardInfo = getCardInfo();
        const owmNumber = cardInfo && cardInfo.owmNumber || '';
        checkInfo({cardNo: owmNumber, type: null}).then(res=>{
            let jumpType = res.jumpType || 0;
            let pay = res.pay || 0;
            let cardType = res.cardType || 1; // 1=物联网卡 2=随身WiFi
            let info = res.info || {};
            // 2024年9月21日10:21:54 yd电话沟通 直接跳转，忽略次数
            jumpType = 1;
            if (jumpType === 1) {
                let params = tansParams({n:owmNumber}) || '';
                if (params) {
                    params = encodeURIComponent(params)
                }
                if (cardType === 1) { // 物联网卡
                    // 如果要跳转到微信客服，判断跳转售前还是售后
                    // location.href = pay === 0 ? info.sqKefu : info.shKefu;
                    console.log("????111",pay === 0 ? info.sqKefu : info.shKefu)

                    const url = pay === 0 ? info.sqKefu : info.shKefu;
                    //https://work.weixin.qq.com/kfid/kfc8003fba090f1eb0e?enc_scene=ENCRabergvhiB5zoupQQuBM7e
                    // location.href = url + '&scene_param=' + params;
                    window.open(url + '&scene_param=' + params)
                    return;
                } else if (cardType === 2) { // 随身WiFi
                    // location.href = pay === 0 ? info.wifiSqKefu : info.wifiShKefu;
                    console.log("????2222", pay === 0 ? info.wifiSqKefu : info.wifiShKefu);

                    const url = pay === 0 ? info.wifiSqKefu : info.wifiShKefu;
                    //https://work.weixin.qq.com/kfid/kfc8003fba090f1eb0e?enc_scene=ENCRabergvhiB5zoupQQuBM7e
                    // location.href = url + '&scene_param=' + params;
                    window.open(url + '&scene_param=' + params);
                    return;
                }
            }
        })
        return;
    }
    if (config.kefuyemianzhixiang === 1) {
        let query = {scene_param: store.state.cardNo};
        router.push({path:'/kefu',query: Object.assign(query,otherInfo)})
    }else{
        location.href = getWxkflj();
    }
}

/**
 * 支付维护弹窗提示
 * @param self vue实例
 */
export function zhifuweihu(self) {
    // 2024年3月17日16:08:27 https://alidocs.dingtalk.com/i/nodes/6LeBq413JAoZljLZtkq9EYoaWDOnGvpb
    // 如果无法调起支付，则新增”维护通知“，内容：系统正在维护，暂时无法支付，请两小时后再试！
    // 按钮：联系客服、知道了（主）
    // 需要“知道了”按钮。然后另外针对所有场景（包含激活费、余额、订购套餐、三合一活动等）的自动调起支付，如果无发调起，则不自动调起支付。且调起支付只有是人工点击才能提示维护弹窗，自动触发（比如返回页、自动停留超时的都没有）的都不提示维护
    self.$dialog.confirm({
        title: "维护通知",
        message: '系统正在维护，暂时无法支付，请两小时后再试！',
        messageAlign: 'left',
        showCancelButton: true,
        confirmButtonText: '知道了',
        cancelButtonText: '联系人工客服',
        getContainer: ".dialog"
    }).then(() => {
    }).catch(() => {
        // location.href = 'https://work.weixin.qq.com/kfid/kfcf122a2ac94c24386';
        location.href = getWxkflj();
    });
}

export function weixinyuming(payInfo,key) {
    const weixinyumingSwitch = store.state.weixinyuming
    if (!weixinyumingSwitch) {
        return false;
    }
    saveCacheWxPayInfo(JSON.stringify(payInfo)).then(res=>{
        const {uuid, domain} = res;
        if (domain) {
            location.href = 'http://' + domain + '/wxpayguodu?uuid=' + uuid + (key && ('&key='+key) || '');
        }else{
            router.push({path:'/wxpayguodu',query:{uuid,key}})
        }
    })
    return true;
}

let gZhifubanwei = '';
let gCanshu = {};
let payInfo = {};
let orderKey = '';
let gResove = null;
function onBridgeReady() {
    // cache.local.set('apn1',JSON.stringify(this.payInfo))
    WeixinJSBridge.invoke('getBrandWCPayRequest', {
            "appId": payInfo.appId,   //公众号ID，由商户传入
            "timeStamp": payInfo.timeStamp,   //时间戳，自1970年以来的秒数
            "nonceStr": payInfo.nonceStr,      //随机串
            "package": payInfo.packageValue,
            "signType": payInfo.signType,     //微信签名方式：
            "paySign": payInfo.paySign //微信签名
        },
        function (res) {
            // cache.local.set('apn2',JSON.stringify(res));
            console.log("调起支付结果", res)
            if (res.err_msg === "get_brand_wcpay_request:ok") {
                Toast('支付成功')
                // 使用以上方式判断前端返回,微信团队郑重提示：
                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                // that.$toast.success("支付成功!");
                gResove && gResove();
                // if ('wushibanwei' === gZhifubanwei) {
                //     // 检查一下跳哪里
                //     let data1 = Object.assign({},store.getters.commonInfo,localGetAN());
                //     getJump2(data1).then(res=>{
                //         // jump  origin=跳转到原系统  register=跳转到注册界面  recharge=跳转到充值界面  weixin=跳转到引导微信中打开界面
                //         let jump = res.jump;
                //         if (jump === 'origin') {
                //             const {cardNo,customId} = res;
                //             if (jumpOwn()) {
                //                 if (cardNo) {
                //                     store.commit('setCardNo', cardNo);
                //                 }
                //                 router.replace({path: '/flowCard'});
                //             }else{
                //                 location.href = 'http://yw.ihaoma.ltd/iot/index.html#/pages/firstRecharge/index';
                //             }
                //         } else if (jump === 'register') {
                //             router.replace({path: '/logon'})
                //         } else if (jump === 'recharge') {
                //             router.replace({path: '/activeOld'})
                //         } else if (jump === 'rechargeNew'){
                //             router.replace({path: '/active'})
                //         } else if (jump === 'weixin') {
                //             // 跳转到引导打开微信页面
                //             location.href=getWxkflj();
                //         }
                //     })
                // }
                if (orderKey) {
                    checkAlipaySuccess(orderKey).then(res=>{
                        if(res.info){
                            this.$toast(res.info)
                        }else if(res.paySuccess) {
                            // 2024年7月31日17:22 技术部内部 yd使用was电脑发出
                            // 支付成功后，有卡号但是没有实名，到实名页面（标题：支付成功 内容：恭喜支付成功，请进入到实名认证后使用 按钮 返回首页 实名认证 ），有卡号但是没有套餐，到套餐。（支付成功，恭喜支付成功！请订购套餐后使用 按钮 返回首页 订购套餐） 1秒停留跳转
                            const jumpType = res.jumpType || 0; // 1=实名 2=购买套餐
                            if (jumpType === 1) {

                                let autoNextTimer = setTimeout(() => {
                                    router.push({path: '/realName'})
                                }, 1000);

                                showZljszfsbtc('支付成功','恭喜支付成功，请进入到实名认证后使用','实名认证','返回首页',()=>{
                                    router.replace({path: '/flowCard'});
                                },()=>{
                                    router.push({path: '/realName'})
                                },()=>{
                                    if (autoNextTimer) {
                                        clearTimeout(autoNextTimer);
                                        autoNextTimer = null;
                                    }
                                })

                            } else if (jumpType === 2) {

                                let autoNextTimer = setTimeout(() => {
                                    router.push({path: '/setMeal'})
                                }, 1000);

                                showZljszfsbtc('支付成功','恭喜支付成功，请订购套餐后使用','订购套餐','返回首页',()=>{
                                    router.replace({path: '/flowCard'});
                                },()=>{
                                    router.push({path: '/setMeal'})
                                },()=>{
                                    if (autoNextTimer) {
                                        clearTimeout(autoNextTimer);
                                        autoNextTimer = null;
                                    }
                                })

                            } else {
                                if ('wushibanwei' === gZhifubanwei) {
                                    // 检查一下跳哪里
                                    let data1 = Object.assign({},store.getters.commonInfo,localGetAN());
                                    getJump2(data1).then(res=>{
                                        // jump  origin=跳转到原系统  register=跳转到注册界面  recharge=跳转到充值界面  weixin=跳转到引导微信中打开界面
                                        let jump = res.jump;
                                        if (jump === 'origin') {
                                            const {cardNo,customId} = res;
                                            if (jumpOwn()) {
                                                if (cardNo) {
                                                    store.commit('setCardNo', cardNo);
                                                }
                                                router.replace({path: '/flowCard'});
                                            }else{
                                                location.href = 'http://yw.ihaoma.ltd/iot/index.html#/pages/firstRecharge/index';
                                            }
                                        } else if (jump === 'register') {
                                            router.replace({path: '/logon'})
                                        } else if (jump === 'recharge') {
                                            router.replace({path: '/activeOld'})
                                        } else if (jump === 'rechargeNew'){
                                            router.replace({path: '/active'})
                                        } else if (jump === 'weixin') {
                                            // 跳转到引导打开微信页面
                                            location.href=getWxkflj();
                                        }
                                    })
                                }
                            }
                        }
                    })
                }
            }
            if (res.err_msg === "get_brand_wcpay_request:fail" || res.err_msg === "get_brand_wcpay_request:cancel") {
                const closeFunction = showZljszfsbtc('支付提醒','如果支付失败，可以重新发起支付。推荐使用支付宝支付','联系客服','返回',()=>{
                    console.log("onConfirm")
                    jumpKefu();
                },()=>{
                    console.log("onCancel")
                    history.go(-1);
                })
                setTimeout(()=>{
                    closeFunction();
                    history.go(-1);
                },3000)
            }

            // if (res.err_msg === "get_brand_wcpay_request:fail") {
            //     Toast.fail("支付失败!");
            // }
            // if (res.err_msg === "get_brand_wcpay_request:cancel") {
            //     Toast.fail("支付已取消!");
            // }
        });
}

function localGetAN() {
    let {a,n} = router.app._route.query;
    let an = getAN();
    if (!a) {
        a = an.a;
    }
    if (!n) {
        n = an.n;
    }
    return {a, n}
}

function isIOS() {
    const userAgent = navigator.userAgent;
    return /iPhone|iPad|iPod/.test(userAgent) && !window.MSStream;
}

export function extracted(res, zhifubanwei, canshu, reject) {
    const {action, info, type, key} = res.data;
    payInfo = info;
    orderKey = key;
    if ('jsapi' === action) { // 微信支付
        if (weixinyuming(info,orderKey)) {
            return;
        }
        if (typeof WeixinJSBridge == "undefined") {
            if (document.addEventListener) {
                document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
            } else if (document.attachEvent) {
                document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
            }
        } else {
            onBridgeReady();
        }
    } else if ('longzhifu' === action) { // 龙支付
        location.href = info;
    } else if ('alipay' === action) { // 支付宝
        if ('wushibanwei' === zhifubanwei) {
            let {a, n} = localGetAN();
            const openId1 = store.getters.openId1;
            const environment = isWeixin() ? "1" : "2";
            let otherInfo = JSON.parse(canshu.otherInfo);
            const queryParams = Object.assign({
                key: info,
                a,
                n,
                openId1,
                environment,
                activeIndex: otherInfo.activeIndex,
                je1: otherInfo.je1,
                je2: otherInfo.je2
            }, store.getters.commonInfo)
            getAlipayDomain().then(res => {
                const alipayDomain = res.msg || 'abc.ihaoma.icu';
                location.href = 'http://' + alipayDomain + '/alipayGuide?' + tansParams(queryParams)
                // let href = 'http://' + alipayDomain + '/alipayGuide?' + tansParams(queryParams)
                // console.log('跳转',href)
            })
        } else if ('feiwushibanwei' === zhifubanwei) {
            let {a, n} = getAN();
            const openId1 = store.getters.openId1;
            const environment = isWeixin() ? "1" : "2";

            const {bt,je} = cache.local.getJSON('fei50canshu') || {};

            const queryParams = Object.assign({
                key: info, a, n, bt,je, openId1, environment,
                oriUrl: encodeURIComponent(location.href.replace('secondPay=1&', '').replace('secondPay=1', ''))
            }, store.getters.commonInfo)
            getAlipayDomain().then(res => {
                const alipayDomain = res.msg || 'abc.ihaoma.icu';
                // 2024年2月26日15:54 yd 技术部-内部
                // 跳转出去后，如果跳回来，检测到充值失败，则直接进入到“选择页”然后用微信支付调出一次。 另外过渡页要能返回。
                // 实现方法：跳转到支付宝域名前，先在localstorage中存一个值，跳转参数带上当前链接，跳回来的时候就知道原链接了
                cache.local.set('secondPay', '1'); // 第二次支付：1=是 其他=否
                let href = 'http://' + alipayDomain + '/fei50alipayGuide?' + tansParams(queryParams);
                location.href = href
                // console.log("跳转",href)
            })
        }
    } else if ('first' === action) { // 首页
        // 跳转到first链接
        if (jumpOwn()) {
            router.push({path: '/flowCard'});
        } else {
            location.href = 'http://yw.ihaoma.ltd/iot/index.html#/pages/firstRecharge/index';
        }
    } else if ('logon' === action) { // 登录页
        router.replace({path: '/logon'})
    } else if ('jsapiJump' === action) { // 非微信环境
        // 跳转到”微信跳转中“图片页
        router.push({path: '/alipaySuccess'})
    } else if ('weihu' === action) { // 维护
        Toast('维护中，请稍后重试')
    } else if ('juheyizhifu' === action) { // 聚合易支付
        // location.href = info;
        removeElementById('jhyzfForm');
        removeElementById('jhyzfScript');
        document.write(info);
    } else if ('wangezhifu' === action) { // 挽歌支付-微信
        let money = 0;
        let cardNo = '';
        if ('wushibanwei' === zhifubanwei) {
            let otherInfo = JSON.parse(canshu.otherInfo);
            money = otherInfo.je1;
            let {a, n} = localGetAN();
            cardNo = n;
        } else if ('feiwushibanwei' === zhifubanwei) {
            const {bt,je} = cache.local.getJSON('fei50canshu') || {};
            money = je;
            let {a, n} = getAN();
            cardNo = n;
        }

        let query = {
            key: info, je:money, cardNo
        }
        // 判断是安卓还是ios
        if (isIOS()) {
            router.push({path:'/iosWGguodu',query})
        }else{
            router.push({path:'/AndroidWGguodu', query})
        }
    } else if ('jingdong' === action) { // 京东支付
        location.href = info;
    } else if ('weidian' === action) { // 微店支付
        location.href = info;
    } else if ('pinduoduo' === action) { // 拼多多支付
        location.href = info;
    } else if ('jumptochoose' === action) { // 选择支付页面
        // store.state.prePay = {zhifubanwei,canshu,res: res.data}
        let cardNo = '';
        let money = '';
        if ('wushibanwei' === zhifubanwei) {
            cardNo = canshu.n;
            const otherInfo = canshu.otherInfo && JSON.parse(canshu.otherInfo) || {}
            const activeIndex = otherInfo.activeIndex;
            if (activeIndex) {
                let txt = '';
                if (activeIndex == '1') {
                    money = '100'; // 充100得360
                } else if (activeIndex == '2') {
                    money = '80';  // 充80得240
                } else if (activeIndex == '3') {
                    money = '66';  // 充66得120
                }
            }
        } else if ('feiwushibanwei' === zhifubanwei) {
            cardNo = canshu.cardNo;
            const {je} = cache.local.getJSON('fei50canshu') || {};
            money = je;
        }
        cache.local.setJSON('prePay', {zhifubanwei, canshu, res: res.data, money});
        router.push({path: '/choosePay', query: {cardNo}});
    }
    if ('jsapi' !== action) {
        reject();
    }
}

function removeElementById(id) {
    var element = document.getElementById(id);
    if (element) {
        element.parentNode.removeChild(element);
    }
}



export function handlePrePay(zhifubanwei,canshu,payType) {
    // 获取支付列表/调起支付
    //         // 支付版位 "wushibanwei" "feiwushibanwei"
    //         private String zhifubanwei;
    //         // 支付方式 PayTypeItem中的type字段 1=支付宝 2=第三方 3=JSAPI 4=龙支付 5=聚合易支付 6=京东支付 7=微店支付
    //         private Integer type;
    //         // 支付参数
    //         private String canshu;
    return new Promise((resolve, reject)=>{
        gZhifubanwei = zhifubanwei;
        gCanshu = canshu;
        gResove = resolve;
        prePay(zhifubanwei,JSON.stringify(canshu),payType).then(res=>{
            extracted(res, zhifubanwei, canshu, reject);
        })

    })
}

export function alipayChangePay(alipayKey) {
    return new Promise((resolve, reject)=>{
        zfbsb(alipayKey).then(res=>{
            const zhifubanwei = res.zhifubanwei;
            const canshu = res.canshu;
            if (zhifubanwei) {
                gZhifubanwei = zhifubanwei;
            }
            if (canshu) {
                gCanshu = canshu;
            }

            const action =  res && res.data && res.data.action || '';
            const sbdyms = res && res.sbdyms || 3; // 失败调用模式 1=弹窗模式 2=直接调用
            if ('weihu' === action || 'none' === action || sbdyms===1) {
                resolve(res);
            }else{
                extracted(res, zhifubanwei, canshu, reject)
            }
        })
    })
}

export function getLocation() {
    return cache.local.getJSON('location') || {};
}
